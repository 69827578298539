import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "svg-icon svg-icon-3tx me-4 mb-sm-0 mb-5 svg-icon-white"
}
const _hoisted_2 = { class: "d-flex w-auto flex-column text-light pe-0 pe-sm-10" }
const _hoisted_3 = { class: "mb-3 text-light" }
const _hoisted_4 = {
  type: "button",
  class: "btn btn-icon ms-sm-auto w-20px",
  "data-bs-dismiss": "alert"
}
const _hoisted_5 = { class: "svg-icon svg-icon-1 fs-2x" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert alert-dismissible d-flex justify-content-around p-3 mb-5 bg-success", ['bg-' + _ctx.color]])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_component_inline_svg, { src: _ctx.icon }, null, 8, ["src"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.translate(_ctx.title)), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
    ]),
    _createElementVNode("button", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, [
        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr061.svg" })
      ])
    ])
  ], 2))
}
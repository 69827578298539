
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { platforms } from "@/store/pinia/Platforms";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";
import UserInfo from "@/core/services/UserInfo";

export default defineComponent({
  name: "kt-increase-points",
  props: {
    classes: {
      type: String,
      default: "btn btn-sm btn-success",
    },
    calibration: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      default: "",
    },
    bot_id: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const user = UserInfo.getUser();

    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const allBotsHolder = AllBots();
    const { findBot } = storeToRefs(allBotsHolder);
    const { setDefaultBotId, fetchBotList } = allBotsHolder;

    onMounted(() => {
      setDefaultBotId(props.bot_id);
    });

    const platformsHolder = platforms();
    const { platformsList } = storeToRefs(platformsHolder);

    const submitButton = ref<HTMLButtonElement | null>(null);
    const submitButton1 = ref<HTMLButtonElement | null>(null);
    const store = useStore();

    const onSubmiting = ref(false);
    const increasePoint = () => {
      if (onSubmiting.value === true) return;
      onSubmiting.value = true;

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      if (submitButton1.value) {
        // eslint-disable-next-line
        submitButton1.value!.disabled = true;
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.post(`bot/${props.bot_id}/calibration`, {
        platform: props.platform,
      })
        .then(() => {
          fetchBotList();
          Swal.fire({
            text: "عملیات با موفقیت انجام شد.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "متوجه شدم !",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          onSubmiting.value = false;
          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
              submitButton.value!.disabled = false;
          }
          if (submitButton1.value) {
            //Deactivate indicator
            submitButton1.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton1.value!.disabled = false;
          }
        });
    };

    return {
      translate,
      increasePoint,
      submitButton,
      submitButton1,
      platformsList,
      findBot,
      user,
      onSubmiting,
    };
  },
});

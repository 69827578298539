
import { defineComponent, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { translate } from "@/core/services/HelpersFun";
// import { ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  emit: ["open"],
  props: {
    data: Object,
    selected_server: Object,
  },
  setup(props, { emit }) {

    const reboot = () => {
      ApiService.post(`admin/server/${props.selected_server?.id}/reboot`, [])
        .then((data) => {
           Swal.fire({
                text: "سرور مورد نظر با موفقیت ریبوت شد.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "بسیار خب",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              emit("open", false);
          emit("open");
        })
        .catch((e) => {
          return;
        });
    };

    const remove = () => {
      ApiService.post(`admin/server/${props.selected_server?.id}/delete`, [])
        .then((data) => {
          emit("open", false);
          emit("open");
          
          Swal.fire({
                text: " سرور حذف شد و تا حدود یک دقیقه دیگر کاربر میتواند فراآیند اتصال مجدد خود را انجام دهد",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "بسیار خب",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });

          // ElNotification({
          //   title: translate("done"),
          //   message: translate("Error in disconnecting!"),
          //   type: "success",
          // });
        })
        .catch((e) => {
          return;
        });
    };

    const test = () =>{
      emit("open", false);
          emit("open");
    }

    return {
      reboot,
      remove,
      translate,
      test,
    };
  },
});


import { computed, defineComponent } from "vue";
import { statusColor, statusIcon } from "@/core/services/HelpersFun";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-alert-h",
  props: {
    title: { type: String, default: "pay attention" },
    message: { type: String, default: "" },
    type: { type: String, default: "success" },
  },
  components: {},
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const icon = computed(() => {
      if (props?.type === "warning")
        return "/media/icons/duotune/general/gen045.svg";
      else if (props?.type === "danger")
        return "/media/icons/duotune/general/gen044.svg";
      else if (props?.type === "info")
        return "/media/icons/duotune/general/gen007.svg";
      return "/media/icons/duotune/general/gen048.svg";
    });

    const color = computed(() => {
      if (props?.type === "warning") return "warning";
      else if (props?.type === "danger") return "danger";
      else if (props?.type === "info") return "info";
      return "success";
    });

    return {
      statusColor,
      translate,
      statusIcon,
      icon,
      color,
    };
  },
});
